import * as React from "react"
import Website from "@src/components/wrappers/website"
import Footer from "@components/marketing/footer"
import Nav from "@src/components/marketing/headerAlt"
import { Link } from "gatsby"

const seo = {
    url: "/privacy",
    title: "Terms of Service • Sheetsync",
    description: "Sheetsync Terms of Service"
}

export default class Privacy extends React.Component {
    render() {
        return (
            <Website seo={seo} marketing>
                <div className="bg-white">
                    <Nav />
                    <div className="text-center px-6 py-10 sm:py-12 md:py-16">
                        <h1 className="mx-auto text-gray-900 font-semibold leading-tight text-3xl max-w-lg sm:text-3xl sm:max-w-2xl md:text-4xl md:max-w-3xl lg:text-5xl lg:max-w-4xl">
                            Sheetsync Terms of Service
    		    </h1>
                    </div>
                </div>


                <div className="max-w-2xl mx-auto pb-12 px-6">
                    <p>Last updated on June 21, 2020.</p>
                    <br />
                    <p className="mb-4">
                        Thank you for using our product! We built it to help you manage your finances easily and efficiently. Because we don't know every one of our customers personally, we have to put in place some Terms of Service to help keep the ship afloat.
            </p>
                    <p className="mb-4">
                        When we say “Services”, we mean the Sheetsync web application created and maintained by Sheetsync, LLC.
            </p>
                    <p className="mb-4">
                        We may update these Terms of Service in the future. Whenever we make a significant change to our policies, we will notify you via email or the website.
            </p>
                    <p className="mb-4">
                        When you use our Services, now or in the future, you are agreeing to the latest Terms of Service. That’s true for any of our existing and future products and all features that we add to our Services over time. There may be times where we do not exercise or enforce any right or provision of the Terms of Service; in doing so, we are not waiving that right or provision. <b>These terms do contain a limitation of our liability.</b>
                    </p>
                    <p>
                        If you violate of any of the terms, we may terminate your account. That’s a broad statement and it means you need to place a lot of trust in us. We do our best to deserve that trust by being open about who we are, how we work, and keeping an open door to your feedback.
            </p>
                    <br />
                    <ol className="list-decimal pl-4">
                        <li className="pt-1">
                            <Link to="/terms#section-1" className="font-semibold text-blue-800 hover:underline">Account terms</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/terms#section-2" className="font-semibold text-blue-800 hover:underline">Payment, refunds, and plan changes</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/terms#section-3" className="font-semibold text-blue-800 hover:underline">Cancellation and termination</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/terms#section-4" className="font-semibold text-blue-800 hover:underline">Modifications to the Service and prices</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/terms#section-5" className="font-semibold text-blue-800 hover:underline">Uptime, security, and privacy</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/terms#section-6" className="font-semibold text-blue-800 hover:underline">Copyright and content ownership</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/terms#section-7" className="font-semibold text-blue-800 hover:underline">Features and bugs</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/terms#section-8" className="font-semibold text-blue-800 hover:underline">Liability</Link>
                        </li>
                    </ol>
                    <div id="section-1" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">Account terms</p>
                        <ol className="list-decimal pl-4">
                            <li className="pt-1">
                                You are responsible for maintaining the security of your Google account and password. The Company cannot and will not be liable for any loss or damage from your failure to comply with this security obligation. We recommend users set up two-factor authentication for added security.
                    </li>
                            <li className="pt-1">
                                You are responsible for all activity that occurs under your account. That includes activity performed by others who have access to your login credentials.
                    </li>
                            <li className="pt-1">
                                You must be a human. Accounts registered by “bots” or other automated methods are not permitted.
                    </li>
                        </ol>
                    </div>
                    <div id="section-2" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">Payment, Refunds, and Plan Changes</p>
                        <ol className="list-decimal pl-4">
                            <li className="pt-1">
                                For paid Services that offer a free trial, we explain the length of trial when you sign up. After the trial period, you need to pay in advance to keep using the Service. If you do not pay, we will freeze your account and it will be inaccessible until you make payment. If your account has been frozen for a while, we will queue it up for auto-cancellation.
                    </li>
                            <li className="pt-1">
                                All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities. Where required, we will collect those taxes on behalf of the taxing authority and remit those taxes to taxing authorities. Otherwise, you are responsible for payment of all taxes, levies, or duties.
                    </li>
                            <li className="pt-1">
                                We'll give you a full refund if you were just charged for your first year of service but you meant to cancel before the free trial expired.
                    </li>
                            <li className="pt-1">
                                If you forgot to cancel your first year of service more than a month ago, we’ll review your account usage and figure out a partial refund based on how many months you were subscribed.
                    </li>
                        </ol>
                    </div>
                    <div id="section-3" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">Cancellation and Termination</p>
                        <ol className="list-decimal pl-4">
                            <li className="pt-1">
                                You are solely responsible for properly canceling your account. Within our Services, we provide a simple no-questions-asked cancellation button. An email or phone request to cancel your account is not automatically considered cancellation. If you need help cancelling your account, you can always contact us.
                    </li>
                            <li className="pt-1">
                                If you cancel the Service before the end of your current paid up year, your cancellation will take effect at the end of the yearly subscription cycle, and you will not be charged again. We do not automatically prorate unused time in the last billing cycle.
                    </li>
                            <li className="pt-1">
                                We have the right to suspend or terminate your account and refuse any and all current or future use of our Services for any reason at any time. Suspension means you and any other users on your account will not be able to access the account or any content in the account. Termination will furthermore result in the deletion of your account or your access to your account, and the forfeiture and relinquishment of all content in your account. We also reserve the right to refuse the use of the Services to anyone for any reason at any time. We have this clause because statistically speaking, out of the thousands of accounts on our Services, there is at least one doing something nefarious. There are some things we staunchly stand against and this clause is how we exercise that stance.
                    </li>
                            <li className="pt-1">
                                Verbal, physical, written or other abuse (including threats of abuse or retribution) of Company employee or officer will result in immediate account termination.
                    </li>
                        </ol>
                    </div>
                    <div id="section-4" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">Modifications to the Service and Prices</p>
                        <ol className="list-decimal pl-4">
                            <li className="pt-1">
                                We reserve the right at any time to modify or discontinue, temporarily or permanently, any part of our Services with or without notice.
                    </li>
                            <li className="pt-1">
                                Sometimes we change the pricing structure for our products. When we do that, we tend to exempt existing customers from those changes. However, we may choose to change the prices for existing customers. If we do so, we will give at least 30 days notice and will notify you via the email address on record. We may also post a notice about changes on our websites or the affected Services themselves.
                    </li>
                        </ol>
                    </div>
                    <div id="section-5" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">Uptime, Security, and Privacy</p>
                        <ol className="list-decimal pl-4">
                            <li className="pt-1">
                                Your use of the Services is at your sole risk. We provide these Services on an “as is” and “as available” basis. We do not offer service-level agreements for our Services, but do take uptime of our applications seriously.
                    </li>
                            <li className="pt-1">
                                We reserve the right to temporarily disable your account if your usage significantly exceeds the average usage of other customers of the Services. Of course, we’ll reach out to the account owner before taking any action except in rare cases where the level of use may negatively impact the performance of the Service for other customers.
                    </li>
                            <li className="pt-1">
                                We take many measures to protect and secure your data through backups, redundancies, and encryption. We enforce encryption for data transmission from the public Internet.
                    </li>
                            <li className="pt-1">
                                When you use our Services, you entrust us with your data. We take that trust to heart. You agree that Sheetsync may process your data as described in our Privacy Policy and for no other purpose. We as humans can access your data for the following reasons:
                        <ol className="list-disc pl-8 py-2">
                                    <li className="pt-1">
                                        To help you with support requests you make. We’ll ask for express consent before accessing your account.
                            </li>
                                    <li className="pt-1">
                                        To safeguard Sheetsync. We’ll look at logs and metadata as part of our work to ensure the security of your data and the Services as a whole.
                            </li>
                                    <li className="pt-1">
                                        To the extent required by applicable law.
                            </li>
                                </ol>
                            </li>
                            <li className="pt-1">
                                We use third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Services. You can see a list of all subprocessors in our Privacy Policy.
                    </li>
                        </ol>
                    </div>
                    <div id="section-6" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">Copyright and Content Ownership</p>
                        <ol className="list-decimal pl-4">
                            <li className="pt-1">
                                All content on the Services must comply with U.S. copyright law.
                    </li>
                            <li className="pt-1">
                                We claim no intellectual property rights over the material you provide to the Services. All materials provided remain yours.
                    </li>
                            <li className="pt-1">
                                You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of the Services, or access to the Services without the express written permission by the Company.
                    </li>
                            <li className="pt-1">
                                You must not modify another website so as to falsely imply that it is associated with the Services or the Company.
                    </li>
                        </ol>
                    </div>
                    <div id="section-7" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">Features and Bugs</p>
                        <ol className="list-decimal pl-4">
                            <li className="pt-1">
                                We design our Services with care, based on our own experience and the experiences of customers who share their time and feedback. However, there is no such thing as a service that pleases everybody. We make no guarantees that our Services will meet your specific requirements or expectations.
                    </li>
                            <li className="pt-1">
                                We also test all of our features extensively before shipping them. As with any software, our Services inevitably have some bugs. We track the bugs reported to us and work through priority ones, especially any related to security or privacy. Not all reported bugs will get fixed and we don’t guarantee completely error-free Services.
                    </li>
                        </ol>
                    </div>
                    <div id="section-8" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">Liability</p>
                        <p className="mb-4">
                            We mention liability throughout these Terms but to put it all in one section:
                </p>
                        <p className="font-semibold italic mb-4">
                            You expressly understand and agree that the Company shall not be liable, in law or in equity, to you or to any third party for any direct, indirect, incidental, lost profits, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Services; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to this Terms of Service or the Services, whether as a breach of contract, tort (including negligence whether active or passive), or any other theory of liability.
                </p>
                        <p className="mb-4">
                            In other words: choosing to use our Services does mean you are making a bet on us. If the bet does not work out, that’s on you, not us. We do our darnedest to be as safe a bet as possible through careful management of the business; investments in security, infrastructure, and talent. If you choose to use our Services, thank you for betting on us.
                </p>
                        <p>
                            If you have a question about any of the Terms of Service, please contact us.
                </p>
                    </div>
                </div>
                <Footer />
            </Website>
        )
    }
}